import { UspsVerificationData } from 'types/Claim'

import { createContext } from 'react'

interface ApplicationData {
  isVerified?: boolean
  canReopen?: boolean | null
  uspsVerificationData?: UspsVerificationData | null
}

// TODO (#7000) merge ApplicationContext and ClaimFormContext
export interface ApplicationContextType {
  applicationData: ApplicationData | null
  setApplicationData: (data: ApplicationData) => void
}

export const ApplicationContext = createContext<ApplicationContextType>({
  applicationData: null,
  setApplicationData: (_formData: unknown) => {
    return
  },
})
