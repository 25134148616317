import { PartialApplication } from 'models/application'
import { ValidationIssue } from 'models/validation-issue'

import { createContext } from 'react'

export type ClaimFormValues = PartialApplication

export type ClaimFormContextType = {
  claimFormValues: ClaimFormValues
  setClaimFormValues: (formData: Partial<ClaimFormValues>) => void
  warnings: ValidationIssue[]
  setWarnings: (warnings: ValidationIssue[]) => void
  maskSensitiveData?: boolean
}

export const ClaimFormContext = createContext<ClaimFormContextType>({
  claimFormValues: {},
  setClaimFormValues: (_formData: unknown) => {
    return
  },
  warnings: [],
  setWarnings: (_warnings: unknown) => {
    return
  },
})
