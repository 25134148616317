import { NextPage } from 'next'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'

import { Session } from 'next-auth'
import { SessionProvider } from 'next-auth/react'
import type { AppProps } from 'next/app'
import { ReactNode, useEffect, useState } from 'react'
import { GridContainer } from '@trussworks/react-uswds'

import 'i18n/i18n'

import { ActiveSessionHandler } from 'components/ActiveSessionHandler/ActiveSessionHandler'
import { AppContextProviders } from 'components/AppContextProviders/AppContextProviders'
import { DefaultLayout } from 'components/layouts/DefaultLayout/DefaultLayout'
import { SessionManager } from 'components/SessionManager/SessionManager'

import 'styles/styles.scss'

import useClickTracker from 'hooks/useClickTracker'

import { useTranslation } from 'react-i18next'
import { useRouter } from 'next/router'
import Script from 'next/script'

import ErrorBoundary from 'components/ErrorBoundary/ErrorBoundary'
import PageLoader from 'components/loaders/PageLoader'
import { MaintenanceChecker } from 'components/MaintenanceChecker/MaintenanceChecker'
import AnalyticsConfigurator from '../components/AnalyticsConfigurator/AnalyticsConfigurator'
import { initializeAWSRum } from '../monitoring/cloudwatch-rum'

export type NextPageWithLayout<P = Record<string, unknown>, IP = P> = NextPage<
  P,
  IP
> & {
  getLayout?: (page: ReactNode) => ReactNode
}

type CustomPageProps = { session: Session }

type CustomAppProps = AppProps<CustomPageProps> & {
  Component: NextPageWithLayout
}

initializeAWSRum()

function ClaimApp(props: CustomAppProps) {
  useClickTracker()
  const { Component, pageProps } = props
  const session = pageProps.session
  const locale = props.router.locale
  const { i18n } = useTranslation()
  const [isLoadingRoute, setIsLoadingRoute] = useState(false)

  useEffect(() => {
    if (locale) i18n.changeLanguage(locale)
  }, [i18n, locale])

  const router = useRouter()
  const getLayout = Component.getLayout ?? ((page) => page)

  // Generate a unique key to force React state to be reset after a navigation.
  // This fixes issues with Dynamic Routes and uncontrolled components (like DatePicker),
  // where an uncontrolled form input could show the previous page's field values
  // https://nextjs.org/docs/pages/api-reference/functions/use-router#resetting-state-after-navigation
  // https://github.com/newjersey/dol-ui-claimant-intake/issues/3423
  // https://github.com/vercel/next.js/issues/9992
  // https://github.com/trussworks/react-uswds/issues/2348
  const pageKey = router.asPath
  const page = <Component key={pageKey} {...pageProps} />

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        retry: 0,
        // Prevent re-queries on page focus
        refetchOnWindowFocus: false,
        // Cache queries up to five minutes by default
        cacheTime: 1000 * 5 * 60,
        // Queries are immediately stale. Can change on a per-query basis.
        staleTime: 0,
      },
    },
  })

  /**
   * Handle client-side page change events (loading state and page view tracking)
   * https://nextjs.org/docs/pages/api-reference/functions/use-router#routerevents
   */
  useEffect(() => {
    const handleRouteChangeStart = (
      _url: string,
      { shallow }: { shallow: boolean }
    ) => {
      if (shallow) return
      setIsLoadingRoute(true)
    }

    const handleRouteChangeError = () => {
      setIsLoadingRoute(false)
    }

    const handleRouteChangeComplete = () => {
      setIsLoadingRoute(false)

      /**
       * Track client-side navigation page changes in Google Analytics
       * https://developers.google.com/analytics/devguides/collection/ga4/views?client_type=gtag
       */
      if (typeof globalThis.gtag !== 'function') return
      globalThis.gtag('event', 'page_view')
    }

    router.events.on('routeChangeStart', handleRouteChangeStart)
    router.events.on('routeChangeError', handleRouteChangeError)
    router.events.on('routeChangeComplete', handleRouteChangeComplete)
    return () => {
      router.events.off('routeChangeStart', handleRouteChangeStart)
      router.events.off('routeChangeError', handleRouteChangeError)
      router.events.off('routeChangeComplete', handleRouteChangeComplete)
    }
  }, [router, setIsLoadingRoute])

  return (
    <>
      {process.env.NEXT_PUBLIC_APP_ENV === 'production' && (
        <>
          <Script
            strategy="lazyOnload"
            src="https://www.googletagmanager.com/gtag/js?id=G-2F7W0D0NDJ"
          />
          <Script strategy="lazyOnload" id="ga-init">{`
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());
                gtag('config', 'G-2F7W0D0NDJ');
              `}</Script>
        </>
      )}
      <ErrorBoundary>
        <SessionProvider session={session}>
          <ActiveSessionHandler>
            <QueryClientProvider client={queryClient}>
              <ReactQueryDevtools initialIsOpen={false} />
              <AnalyticsConfigurator />
              <AppContextProviders>
                <DefaultLayout>
                  <SessionManager forceOpen={false} />
                  <GridContainer className="margin-top-2">
                    <ErrorBoundary>
                      <MaintenanceChecker>
                        {isLoadingRoute ? <PageLoader /> : getLayout(page)}
                      </MaintenanceChecker>
                    </ErrorBoundary>
                  </GridContainer>
                </DefaultLayout>
              </AppContextProviders>
            </QueryClientProvider>
          </ActiveSessionHandler>
        </SessionProvider>
      </ErrorBoundary>
    </>
  )
}

export default ClaimApp
